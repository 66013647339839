<template>
  <v-dialog v-model="display" max-width="600">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">
          {{ $t('users.userManagement.actions.createUser') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-form>
        <v-container class="px-7">
          <v-text-field
            v-model="form.data.email"
            :error-messages="errors.email"
            :label="$t('forms.fields.email')"
          />
          <v-row>
            <v-col md="6">
              <v-text-field
                type="password"
                v-model="form.data.password"
                :error-messages="errors.password"
                :label="$t('forms.fields.password')"
                :hint="$t('forms.hints.password')"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                type="password"
                v-model="form.data.password_confirmation"
                :error-messages="errors.password_confirmation"
                :label="$t('forms.fields.passwordConfirmation')"
                :hint="$t('forms.hints.password')"
              />
            </v-col>
          </v-row>

          <v-select
            v-model="form.data.role"
            :error-messages="errors.role"
            :label="$t('forms.fields.role')"
            :items="roles"
            item-value="type"
            item-text="label"
          />

          <v-select
            v-if="isOrganizer || isProjectManager"
            v-model="form.data.organizer_id"
            :error-messages="errors.organizer_id"
            :label="$t('forms.fields.organization')"
            :items="organizers"
            item-value="id"
            item-text="name"
          />

          <v-select
            v-if="isAssociation"
            v-model="form.data.association_id"
            :error-messages="errors.association_id"
            :label="$t('forms.fields.association')"
            :items="associations"
            item-value="id"
            item-text="name"
          />

          <v-autocomplete
              v-if="isExhibitor"
              v-model="form.data.company_id"
              :error-messages="errors.company_id"
              :label="$t('forms.fields.exhibitor')"
              :items="companies"
              item-value="attributes.company_id"
              item-text="attributes.company"
              @update:search-input="loadCompanies"
          >
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center justify-space-between w-100">
                <div>
                  {{ item.attributes.company }}
                  <small class="ml-4">
                    ({{ $t('accreditation.registeredSince').toLowerCase() }} 
                    {{ item.relationships.company_model.created_at | dateByLocale }})
                  </small>
                </div>
                <v-chip 
                  small 
                  :color="item.attributes.accreditation_approved_at && !item.attributes.accreditation_rejected_at ? 'green' : 'red'" 
                  class="mr-3"
                  outlined
                >
                  {{ item.attributes.accreditation_approved_at && !item.attributes.accreditation_rejected_at 
                    ? $t('company.accredited') 
                    : $t('company.notAccredited') }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex align-center justify-space-between w-100">
                        <div>
                          {{ item.attributes.company }}
                          <small class="ml-4">
                            ({{ $t('accreditation.registeredSince').toLowerCase() }} 
                            {{ item.relationships.company_model.created_at | dateByLocale }})
                          </small>
                        </div>
                        <v-chip 
                          small 
                          :color="item.attributes.accreditation_approved_at && !item.attributes.accreditation_rejected_at ? 'green' : 'red'" 
                          class="mr-3"
                          outlined
                        >
                          {{ item.attributes.accreditation_approved_at && !item.attributes.accreditation_rejected_at 
                            ? $t('company.accredited') 
                            : $t('company.notAccredited') }}
                        </v-chip>
                      </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-row>
            <v-col md="6">
              <v-text-field
                v-model="form.data.first_name"
                :error-messages="errors.first_name"
                :label="$t('forms.fields.firstName')"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="form.data.last_name"
                :error-messages="errors.last_name"
                :label="$t('forms.fields.lastName')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-btn
                class="elevation-0 secondary float-right"
                :loading="form.loading"
                @click="handleSubmit"
              >
                {{ $t('common.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  required,
  email,
  sameAs,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import { USER_ROLES } from '@/enums/userRole'
import debounce from "lodash/debounce";

export default {
  name: 'CreateUserModal',

  props: {
    roles: {
      type: Array,
      required: true,
    },
  },

  mixins: [validation],

  data() {
    return {
      display: false,
      companies: [],

      form: {
        loading: false,

        data: {
          email: '',
          password: '',
          password_confirmation: '',
          role: '',
          organizer_id: null,
          association_id: null,
          company_id: null,
          first_name: '',
          last_name: '',
        },

        validationRules: {
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(6),
          },
          password_confirmation: {
            required,
            minLength: minLength(6),
            sameAsPassword: sameAs('password'),
          },
          role: {
            required,
          },
          organizer_id: requiredIf(function () {
            return this.isOrganizer
          }),
          association_id: requiredIf(function () {
            return this.isAssociation
          }),
          company_id: {
            required: requiredIf(function () {
              return this.isExhibitor
            })
          },
          first_name: {
            required,
          },
          last_name: {
            required,
          },
        },

        custom: {
          validationSubstitutions: {
            password_confirmation: {
              sameAsPassword: {
                sameAsField: this.$t('forms.fields.password'),
              },
              minLength: {
                length: 6,
              },
            },
            password: {
              minLength: {
                length: 6,
              },
            },
          },
        },
      },
    }
  },

  async created() {
    let response = (await this.$axios.get('/api/companies', {
      params: {
        'languages': '*',
        'include': [
          'country',
          'company',
        ],
        'page[number]': 1,
        'page[size]': 5,
      },
    })).data

    this.companies = this.companies.concat(response.data)
  },

  computed: {
    ...mapGetters('organizer', ['organizers']),

    ...mapGetters('association', ['associations']),

    isOrganizer() {
      return this.form.data.role === USER_ROLES.ORGANIZER
    },

    isExhibitor() {
      return this.form.data.role === USER_ROLES.EXHIBITOR
    },

    isProjectManager() {
      return this.form.data.role === USER_ROLES.PROJECT_MANAGER
    },

    isAssociation() {
      return this.form.data.role === USER_ROLES.ASSOCIATION
    },

    isAdmin() {
      return this.form.data.role === USER_ROLES.ADMINISTRATOR
    },

    isSupervisor() {
      return this.form.data.role === USER_ROLES.SUPERVISOR
    },
  },

  methods: {
    handleIds(data) {
      if (this.isOrganizer || this.isProjectManager) {
        delete data.association_id
        delete data.company_id
      }
      if (this.isAssociation) {
        delete data.organizer_id
        delete data.company_id
      }

      if (this.isAdmin || this.isSupervisor) {
        delete data.organizer_id
        delete data.association_id
        delete data.company_id
      }

      return data
    },

    async handleSubmit() {
      this.resetServerErrors()
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        if (this.isExhibitor) {
          await this.$axios.post(`/api/companies/${this.form.data.company_id}/users`, this.form.data)
        } else {
          await this.$axios.post(`/api/users`, this.handleIds(this.form.data))
        }

        if (this.tradeFairIdToAssign) {
          await this.handleTradeFairAssign()
        }

        this.$snackbar(this.$t('common.successApiMessage'))
        this.$emit('submit')
        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }
    },

    loadCompanies: debounce(async function (search) {
      if (!search || search.length < 3) {
        return
      }

      let response = (await this.$axios.get('/api/companies', {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
          ],
          'filter[company]': search,
          'page[number]': 1,
          'page[size]': 5,
        },
      })).data

      this.companies = this.companies.concat(response.data)
    }, 500),

    show() {
      this.clearFormData()

      this.$nextTick(() => {
        this.$v.$reset()
      })

      this.display = true
    },

    hide() {
      this.display = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
